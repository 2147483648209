export type ActCompletionEvent = {
  id: string;
  displayedName: string;
  inProgress?: boolean;
  evaluation?: 'GOOD' | 'BAD' | 'FAIL';
};

export type LoadFeedbacksEvent = {
  isLoading: boolean;
};

export type UserActionToastsEvent = {
  userActionToasts: { id: string; evaluation: 'GOOD' | 'BAD' | 'FAIL'; body: string }[];
};

export type STTFailedEvent = {
  reason: string;
  partialSpeechDetected: string;
};

export enum GraphEventTypes {
  ActCompletion = 'actCompletion',
  LoadFeedbacks = 'loadFeedbacks',
  UserActionToasts = 'userActionToasts',
  STTFailed = 'sttFailed'
}

export type GraphEvent = {
  type: GraphEventTypes;
  content: ActCompletionEvent | LoadFeedbacksEvent | UserActionToastsEvent | STTFailedEvent;
};
