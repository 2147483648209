import Stepper, { StepContext } from '@UIKit/Stepper/Stepper';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback
} from 'react';
import defs from '@/assets/new-ui/defs.svg';
import TopBar from '@/components/TopBar/TopBar';
import FeedbackDetail from './FeedbackDetail/FeedbackDetail';
import FeedbackGlobal from './FeedbackGlobal/FeedbackGlobal';
import { useParams } from 'react-router-dom';
import { useFeedback } from '@/hooks/useFeedbacks';
import { useExerciseSession } from '@/hooks/useExerciseSession';
import { useExerciseNavigation } from '@/hooks/useExerciseNavigation';
import { useScrollDirection, SCROLL_DIRECTIONS } from '@/hooks/useScrollDirection';
import { simulateWheelScroll } from '@/helpers/simulateWheelScroll';

const Feedback = () => {
  const { exerciseSessionID, exerciseID } = useParams();
  const { setActiveStepId } = useContext(StepContext);
  const {
    unlockedTrophyID,
    detailedFeedbacks,
    improvementAxes,
    statsFeedbacks,
    isLoading,
    errorMessage
  } = useFeedback(exerciseSessionID);
  const { exercise, errorMessage: errorMessageSession } = useExerciseSession(
    exerciseID,
    exerciseSessionID
  );
  const { quitExercise, restartExercise } = useExerciseNavigation(exerciseID);
  const [isDetailSectionDisplayed, setIsDetailSectionDisplayed]: [
    boolean,
    Dispatch<SetStateAction<boolean>>
  ] = useState<boolean>(false);

  const [isTrophyExplanationsModalOpened, setIsTrophyExplanationsModalOpened] =
    useState<boolean>(false);
  const [isUADetailsTextModalOpened, setIsUADetailsTextModalOpened] = useState<boolean>(false);

  const handleTrophyModalChange = useCallback((isOpen: boolean) => {
    setIsTrophyExplanationsModalOpened(isOpen);
  }, []);

  const handleUADetailsModalChange = useCallback((isOpen: boolean) => {
    setIsUADetailsTextModalOpened(isOpen);
  }, []);

  const detailSectionRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollDirection = useScrollDirection(scrollContainerRef);

  const handleQuitExercise = () => {
    const textModal = exercise ? exercise.CustomizationValues : {};
    quitExercise(textModal);
  };

  useEffect(() => {
    if (isTrophyExplanationsModalOpened || isUADetailsTextModalOpened) {
      return;
    }
    switch (scrollDirection) {
      case SCROLL_DIRECTIONS.UP:
        // hack to make the scrollToTop work, after a scrollToDetail launch by clicking on an element,
        // the scroll event is not triggered, so we simulate a scroll event to make the scrollToTop work
        simulateWheelScroll(10);
        scrollToTop();
        break;
      case SCROLL_DIRECTIONS.DOWN:
        scrollToDetail();
        break;
      default:
        break;
    }
  }, [scrollDirection]);

  useEffect(() => {
    if (isDetailSectionDisplayed) {
      scrollToDetail();
    }
  }, [isDetailSectionDisplayed]);

  useEffect(() => {
    if (detailedFeedbacks) {
      setActiveStepId(detailedFeedbacks[0].id || '');
    }
  }, [detailedFeedbacks]);

  const onClickOnShowDetailLink = (targetUserActionId?: string) => {
    if (targetUserActionId && detailedFeedbacks) {
      const activeStep = detailedFeedbacks.find((step) =>
        step.userActions.find(
          (ua) =>
            ua.id === targetUserActionId && (ua.evaluation === 'BAD' || ua.evaluation === 'FAIL')
        )
      );
      if (activeStep) {
        setActiveStepId(activeStep.id);
      }
    }

    scrollToDetail();
    setIsDetailSectionDisplayed(true);
  };

  const scrollToDetail = () => {
    scrollContainerRef.current?.scrollTo({
      top: detailSectionRef.current?.offsetTop,
      behavior: 'smooth'
    });
  };

  const scrollToTop = () => {
    scrollContainerRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const handleRestartExercise = () => {
      restartExercise();
    };

    window.sdk.event().on('restartExercise', handleRestartExercise);
  }, []);

  if (isLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="spinner-loader dark mx-auto block"></div>
      </div>
    );
  }

  if (errorMessage) {
    return (
      <div className="flex h-screen items-center justify-center text-red-500">{errorMessage}</div>
    );
  }

  if (errorMessageSession) {
    return (
      <div className="flex h-screen items-center justify-center text-red-500">
        {errorMessageSession}
      </div>
    );
  }

  if (!unlockedTrophyID || !detailedFeedbacks) {
    return (
      <div className="flex h-screen items-center justify-center">
        {isLoading ? 'Loading feedback data...' : 'No feedback data available.'}
      </div>
    );
  }

  return (
    <>
      <div ref={scrollContainerRef} className="scroll-container">
        <div className="flex min-h-[calc(100vh-theme(spacing.28))] flex-col">
          <TopBar className="w-full" />

          <div className="centered-wide-row mb-1 flex flex-1 flex-col pt-8">
            <FeedbackGlobal
              unlockedTrophyID={unlockedTrophyID}
              statsFeedbacks={statsFeedbacks}
              improvementAxes={improvementAxes}
              className="sm:px-6"
              onClickOnShowDetailLink={onClickOnShowDetailLink}
              handleQuitExercise={handleQuitExercise}
              onTrophyModalChange={handleTrophyModalChange}
            />
          </div>
        </div>

        <div ref={detailSectionRef} className="centered-wide-row w-full py-8 sm:!px-12">
          <Stepper
            initialSteps={detailedFeedbacks}
            callback={onClickOnShowDetailLink}
            isTabMode={isDetailSectionDisplayed}
          />
        </div>

        <div
          id="detail"
          className={`${
            !isDetailSectionDisplayed
              ? 'hidden'
              : 'not-important-opacity-0 animate-fadeIn [animation-delay:.3s]'
          } centered-wide-row flex min-h-[calc(100vh-theme(spacing.28))] flex-col pb-8`}>
          <FeedbackDetail
            detailedFeedbacks={detailedFeedbacks}
            unlockedTrophyID={unlockedTrophyID}
            handleQuitExercise={handleQuitExercise}
            className="mb-8 mt-auto sm:px-6"
            onUADetailsModalChange={handleUADetailsModalChange}
          />
          <svg
            width="126"
            height="96"
            viewBox="0 0 126 96"
            className="mx-auto mt-auto h-auto w-16 fill-brand"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false">
            <use href={`${defs}#watermark`} />
          </svg>
        </div>
      </div>
    </>
  );
};

export default Feedback;
