import { GraphEvent, GraphEventTypes } from './GraphEvent';

export type GraphObserverUpdateCallback = (event: GraphEvent) => void;

/**
 * Observer class that handles graph-related events and updates.
 * Implements the Observer pattern for graph event notifications.
 */
export class GraphObserver {
  /**
   * Creates a new GraphObserver instance.
   * @param name - The identifier name of the observer (should be unique)
   * @param eventType - The type of graph events this observer should handle
   * @param updateCallback - The callback function to execute when a matching event occurs
   */
  constructor(
    public name: string,
    public eventType: GraphEventTypes,
    public updateCallback: GraphObserverUpdateCallback
  ) {}

  /**
   * Processes an incoming graph event.
   * Only executes the callback if the event type matches the observer's configured event types.
   * @param event - The graph event to process
   */
  update(event: GraphEvent): void {
    if (this.eventType !== event.type) {
      return;
    }

    this.updateCallback(event);
  }

  /**
   * Checks if the observer is currently active.
   * @returns Always returns true in the base implementation
   */
  isActive(): boolean {
    return true;
  }
}
