export default class CookieManager {
  static setCookie(name, value, options = {}) {
    const defaults = {
      path: '/',
      sameSite: 'Lax',
      expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toUTCString() // 7 days
    };

    const secure = window.location.protocol === 'https:';
    const opts = {
      ...defaults,
      ...options
    };
    let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}; ${Object.entries(
      opts
    )
      .map(([key, val]) => `${key}=${val}`)
      .join('; ')}`;

    if (secure) {
      cookieString += '; Secure';
    }

    document.cookie = cookieString;
  }

  static getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      const cookieValue = parts.pop().split(';').shift();
      return cookieValue ? decodeURIComponent(cookieValue) : null;
    }
    return null;
  }

  static deleteCookie(name) {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }
}
