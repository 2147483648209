import { GraphEventTypes } from '@/AppClasses/ExerciseScenario/GraphNotifier/GraphEvent';
import {
  GraphObserver,
  GraphObserverUpdateCallback
} from '@/AppClasses/ExerciseScenario/GraphNotifier/GraphObserver';

export function useActsCompletionObserver(
  updateCallback: GraphObserverUpdateCallback
): GraphObserver {
  return new GraphObserver('ActsCompletionObserver', GraphEventTypes.ActCompletion, updateCallback);
}
