import React from 'react';
import log from 'loglevel';
import Ask from './Ask';
import CamUsed from './CamUsed';
import Refuse from './Refuse';
import Wait from './Wait';

export default class GetUserMedia extends React.PureComponent {
  state = {
    initialized: false,
    showPopinInfo: false,
    showPopinRefuse: false,
    changeBrowser: false,
    authorized: false,
    waitAuthorization: false
  };
  listeners = [];

  async componentDidMount() {
    if (this.props.OnUpdateStatus) this.props.OnUpdateStatus('processing');
    let check = await this.check();
    this.setState({ showPopinInfo: check });
    if (check) {
      this.initIsDone();
      this.forceUpdate();
    }
    if (!check || this.props.askOnLoad) {
      try {
        this.ask();
      } catch (err) {
        log.debug('err', err);
      }
    }

    this.listeners.push(
      window.sdk.event().on('getUserMediaAsk', () => {
        this.ask();
      })
    );

    this.listeners.push(
      window.sdk.event().on('getUserMediaAsk2', () => {
        this.ask2();
      })
    );
  }

  componentWillUnmount() {
    if (this.listeners) {
      for (const i in this.listeners) {
        if (typeof this.listeners[i] === 'function') {
          this.listeners[i]();
        }
      }
    }
  }
  initIsDone = (isError = false) => {
    if (this.props.OnUpdateStatus) this.props.OnUpdateStatus(isError ? 'failed' : 'initialized');
    this.setState({ initialized: true });
  };

  async check() {
    return await window.sdk.videoconf().mediaDevices().needAccept();
  }

  ask2 = async () => {
    this.step2 = true;
    this.ask();
  };

  ask = async () => {
    await this.setState({
      showPopinInfo: false,
      showPopinRefuse: false,
      waitAuthorization: true
    });
    await this.getStream();
  };

  async getStream() {
    this.props.showLoader(true);
    let stream;
    if (window.testMode && window.testMode.noDevicesMode) {
      stream = new MediaStream();
    } else {
      try {
        stream = await window.sdk.videoconf().mediaDevices().getUserMedia();
      } catch (error) {
        log.error('Error getting user media:', error);
        stream = { error: true, errorObject: error };
      }
    }

    if (stream.error && stream.errorObject && stream.errorObject.name !== 'NotFoundError') {
      this.setState({
        showPopinRefuse: true,
        waitAuthorization: true
      });
      this.initIsDone(true);
      if (stream.errorObject.name === 'NotAllowedError' || stream.errorObject.name === 'AbortError')
        //window.localStorage.setItem('webcamPermission', 'denied');
        log.debug(
          'GetUserMedia error',
          stream.errorObject.name + ': ' + stream.errorObject.message,
          stream
        );
      window.sdk.usersActivity().createOne('GetUserMediaError', { Error: stream.errorObject.name });
      if (stream.errorObject.name === 'NotReadableError') {
        this.setState({
          camUsed: true
        });
      }
      if (window.zE) {
        window.zE('webWidget', 'show');

        window.zE('webWidget:on', 'close', function () {
          window.zE('webWidget', 'show');
        });
      }
      this.props.showLoader(false);
    } else {
      this.setState({
        authorized: true
      });
      this.initIsDone();
      this.props.onStreamReady({
        stream
      });
      this.props.showLoader(false);
    }
  }

  renderPopin() {
    this.props.onButtonToShow(
      this.state.changeBrowser,
      this.state.camUsed,
      this.state.showPopinInfo,
      this.state.showPopinRefuse,
      this.state.waitAuthorization
    );

    if (this.state.camUsed) {
      return <CamUsed />;
    }

    if (this.state.showPopinInfo) {
      return <Ask />;
    }

    if (this.state.showPopinRefuse) {
      return <Refuse showTutorial={this.props.showTutorial} />;
    }

    if (this.state.waitAuthorization && this.props.noShowAsk !== true) {
      return <Wait showTutorial={this.props.showTutorial} />;
    }

    return null;
  }

  render() {
    if (this.state.authorized || !this.state.initialized) {
      return null;
    }

    this.props.showLoader(false);

    return <>{this.renderPopin()}</>;
  }
}
