import log from 'loglevel';
import { CognitoUser, AuthenticationDetails, CognitoUserPool } from 'amazon-cognito-identity-js';
import CookieManager from './CookieManager';

export default class Cognito {
  constructor(sdk) {
    this.sdk = sdk;
  }

  getUsernameFromToken(token) {
    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      return payload.email || payload['cognito:username'];
    } catch (e) {
      log.error('Error parsing token:', e);
      return null;
    }
  }

  getExpirationFromToken(token) {
    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      return payload.exp;
    } catch (e) {
      log.error('Error parsing token:', e);
      return 0;
    }
  }

  async loginInfo() {
    return {
      UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID
    };
  }

  async init() {
    let loginInfo = await this.loginInfo();
    this.userPool = new CognitoUserPool(loginInfo);
  }

  async login(username, password) {
    return new Promise((resolve) => {
      const authenticationDetails = new AuthenticationDetails({
        Username: username,
        Password: password
      });

      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: this.userPool
      });

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (session) => {
          log.debug('Cognito', 'authentication successful', session);
          // Store tokens in cookies
          CookieManager.setCookie('accessToken', session.getAccessToken().getJwtToken());
          CookieManager.setCookie('idToken', session.getIdToken().getJwtToken());
          CookieManager.setCookie('refreshToken', session.getRefreshToken().getToken());
          resolve({
            state: 'SUCCESS',
            session
          });
        },
        onFailure: (error) => {
          log.error('Cognito', 'authentication failed', error);
          resolve({
            state: 'ERROR',
            error
          });
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          // User needs to set a new password
          log.debug('Password change required', userAttributes, requiredAttributes);
          userAttributes.name = username;
          delete userAttributes.email;
          delete userAttributes.email_verified;
          const newPassword = password; // Get new password from user input
          cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
            onSuccess: (session) => {
              log.debug('Password changed successfully:', session);
              resolve({
                state: 'SUCCESS',
                session
              });
            },
            onFailure: (error) => {
              log.error('Error changing password:', error);
              resolve({
                state: 'ERROR',
                error
              });
            }
          });
        }
      });
    });
  }

  async validateSession() {
    return new Promise((resolve) => {
      // Check cookies first
      const accessToken = CookieManager.getCookie('accessToken');
      const idToken = CookieManager.getCookie('idToken');
      const refreshToken = CookieManager.getCookie('refreshToken');

      if (!accessToken || !idToken || !refreshToken) {
        resolve({
          state: 'NO_VALID'
        });
        return;
      }

      // Create cognito user from stored data
      const userData = {
        Username: this.getUsernameFromToken(idToken),
        Pool: this.userPool
      };
      const cognitoUser = new CognitoUser(userData);

      // Set the tokens directly
      const session = {
        getAccessToken: () => ({
          getJwtToken: () => accessToken,
          getExpiration: () => this.getExpirationFromToken(accessToken)
        }),
        getIdToken: () => ({
          getJwtToken: () => idToken,
          getExpiration: () => this.getExpirationFromToken(idToken)
        }),
        getRefreshToken: () => ({
          getToken: () => refreshToken
        }),
        isExpired: () => {
          const currentTimestamp = Math.floor(new Date().getTime() / 1000);
          return this.getExpirationFromToken(accessToken) > currentTimestamp;
        }
      };

      // Validate the session
      // Vérifier si la session complète est valide
      let isValid = session.isExpired();
      if (!isValid) {
        resolve({
          state: 'EXPIRED'
        });
        return;
      }

      // Vérifier spécifiquement l'expiration de l'accessToken
      const currentTimestamp = Math.floor(new Date().getTime() / 1000);

      const isAccessTokenExpired = session.getAccessToken().getExpiration() < currentTimestamp;
      if (isAccessTokenExpired) {
        resolve({
          state: 'EXPIRED'
        });
        return;
      }

      // Vérifier spécifiquement l'expiration de l'idToken
      const isIdTokenExpired = session.getIdToken().getExpiration() < currentTimestamp;
      if (isIdTokenExpired) {
        resolve({
          state: 'EXPIRED'
        });
        return;
      }

      resolve({
        state: 'VALID',
        accessToken: session.getAccessToken().getJwtToken(),
        idToken: session.getIdToken().getJwtToken()
      });
    });
  }

  async refreshSession() {
    return new Promise((resolve) => {
      const cognitoUser = this.userPool.getCurrentUser();
      if (!cognitoUser) {
        resolve({
          state: 'ERROR'
        });
        return;
      }

      cognitoUser.getSession(function (error, session) {
        if (error) {
          resolve({
            state: false,
            error
          });
          return;
        }

        // Si la session est invalide (c'est-à-dire que les jetons sont expirés), renouvelez-la
        const refreshToken = session.getRefreshToken();
        if (refreshToken && refreshToken.getToken()) {
          cognitoUser.refreshSession(refreshToken, (refreshErr, newSession) => {
            if (refreshErr) {
              resolve({
                state: 'ERROR',
                error: refreshErr
              });
              log.error('Cognito', 'Refresh session error:', refreshErr);
              return;
            }

            let accessToken = newSession.getAccessToken().getJwtToken();
            let idToken = newSession.getIdToken().getJwtToken();

            log.debug('Cognito', 'Session has been refreshed!');
            log.debug('Cognito', 'New Access Token:', accessToken);
            log.debug('Cognito', 'New ID Token:', idToken);

            resolve({
              state: 'SUCCESS',
              accessToken,
              idToken
            });
          });
        } else {
          log.error('Cognito', 'No refresh token available.');
          resolve({
            state: 'ERROR',
            error: 'No refresh token available.'
          });
        }
      });
    });
  }

  async logout() {
    const cognitoUser = this.userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.signOut();
      // Clear cookies
      CookieManager.deleteCookie('accessToken');
      CookieManager.deleteCookie('idToken');
      CookieManager.deleteCookie('refreshToken');

      // Clear Cognito data from localStorage
      Object.keys(localStorage)
        .filter((key) => key.startsWith('CognitoIdentityServiceProvider.'))
        .forEach((key) => localStorage.removeItem(key));

      log.debug('Cognito', 'User has been signed out and storage cleared.');
    }
  }

  async forgotPassword({ email }) {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: this.userPool
    });

    return new Promise((resolve) => {
      cognitoUser.forgotPassword({
        onSuccess: function (data) {
          log.debug('Cognito', 'forgotPassword onSuccess', data);
          resolve({
            state: 'success',
            info: data
          });
        },
        onFailure: function (error) {
          log.debug('Cognito', 'forgotPassword onFailure', error);
          resolve({
            state: 'fail',
            info: error
          });
        }
      });
    });
  }

  async confirmForgotPassword({ email, code, newPassword }) {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: this.userPool
    });

    return new Promise((resolve) => {
      cognitoUser.confirmPassword(code, newPassword, {
        onSuccess: function () {
          log.debug('Cognito', 'confirmPassword onSuccess');
          resolve({
            state: 'success'
          });
        },
        onFailure: function (error) {
          log.debug('Cognito', 'confirmPassword onFailure', error);
          resolve({
            state: 'fail',
            info: error
          });
        }
      });
    });
  }
}
