import log from 'loglevel';
import ExerciseNode from './ExerciseNode';
import ValueBool from './ValueBool';
import NodePort from '../NodePort';

export default class SetBool extends ExerciseNode {
  // Ports
  Input = new NodePort('Input', 'input', this);
  Set = new NodePort('Set', 'output', this);

  // Parameters
  Target = '';
  Value = false;

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);

    this.Target = iProperties.Target;
    this.Value = iProperties.Value;

    //log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName + ", id = " + this.ID + ", target = " + this.Target + ", value = " + this.Value);
  }

  async OnActivated(iActivationLink, iIsRewindMode = false) {
    await super.OnActivated(iActivationLink, iIsRewindMode);

    this.SetValue();

    if (iIsRewindMode) {
      return;
    }

    this.SetActive(false);
  }

  SetValue() {
    // Directly set the value if specified
    if (this.Target !== '') {
      log.debug(this.GetIdentity() + " SetValue '" + this.Value + "' to '" + this.Target + "'.");
      this.Graph.SetBoolValue(this.Target, this.Value);
    }

    // Set the value to all connected nodes
    log.debug(
      this.GetIdentity() +
        " SetValue '" +
        this.Value +
        "' to " +
        this.Set.GetConnectionsCount() +
        ' nodes: ' +
        this.Set.ListPortConnections()
    );
    this.Set.GetConnectedNodes().forEach((node) => {
      if (node instanceof ValueBool) {
        node.SetValue(this.Value);
      }
    });
  }

  PrintParameters() {
    //log.debug("ValueBool: ID = " + this.ID + ", Name = " + this.Name + ".");
  }
}
