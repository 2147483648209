import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Translation } from '@/components/Utilities/Translation';
import { fixBody, unfixBody } from '@/helpers/fix-body';
import { DEFAULT_LANGUAGE } from '@/helpers/getTolgeeInstance';
import frFlag from '../../assets/icons/flags/fr.png';
import enFlag from '../../assets/icons/flags/en.png';

type LanguageType = 'fr' | 'en';

const Menu = ({
  isExercisePlaying,
  isFeedBack,
  fancyFunctions
}: {
  isExercisePlaying: boolean;
  isFeedBack: boolean;
  fancyFunctions: {
    resume: () => void;
    stop: () => void;
    restart: (step?: string) => void;
    showZendesk: () => void;
  };
}) => {
  const isItFirstExercice = window.location.pathname === '/exercise/1';

  const [language, setLanguage]: [LanguageType, Dispatch<SetStateAction<LanguageType>>] =
    useState<LanguageType>(window.sdk.getLanguage() || DEFAULT_LANGUAGE);
  const [version, setVersion]: [string, Dispatch<SetStateAction<string>>] = useState<string>('');

  const toggleLanguage = () => {
    const newLanguage: LanguageType = language === 'en' ? 'fr' : 'en';
    setLanguage(newLanguage);
    window.sdk.setLanguage(newLanguage);
  };

  const logout = async () => {
    await window.sdk.user().logout();
  };

  const goToDevicesConfig = () => {
    window.sdk.event().emit('modalOpen', {
      isBackdropCloseButton: false,
      content: (
        <>
          <h2>
            <Translation keyName="main.setup">Configuration</Translation>
          </h2>
          <p>
            <Translation keyName="main.quit_exo">
              Voulez-vous quitter cet exercice pour aller à la page de configuration de votre micro
              et caméra ?
            </Translation>
          </p>
          <div className="flex items-center justify-center space-x-6">
            <button
              className="cta cta--white"
              onClick={() => {
                window.sdk.event().emit('modalClose');
              }}>
              <Translation keyName="general.no">Non</Translation>
            </button>
            <button
              className="cta cta--accent"
              onClick={() => {
                window.sdk.videoconf().mediaDevices().resetDevice();
                window.sdk.event().emit('modalClose');
                fancyFunctions.restart('settings');
              }}>
              <Translation keyName="general.yes">Oui</Translation>
            </button>
          </div>
        </>
      )
    });
  };

  useEffect(() => {
    fixBody();

    const fetchVersion = async () => {
      try {
        const response = await window.sdk.fetchInternalAPI().get('/version');
        setVersion(response.version);
      } catch (error) {
        console.error('Failed to fetch version:', error);
      }
    };

    fetchVersion();

    return () => {
      unfixBody();
    };
  });

  const modalContent = (
    <section className="fixed left-0 top-12 z-40 flex min-h-[calc(100vh-theme(spacing.12))] w-full flex-col bg-white/50 backdrop-blur-lg">
      <ul className="centered-row my-8 [&.centered-row]:max-w-half-row [&_a:hover]:text-accent [&_a]:transition-colors [&_button:hover]:text-accent [&_button]:transition-colors [&_li]:mt-4 [&_li]:text-xl [&_li]:font-semibold [&_li]:md:text-4xl">
        {isExercisePlaying && (
          <li>
            <button className="text-left" onClick={fancyFunctions.resume}>
              <Translation keyName="main.menu.resume_ex">Reprendre l'exercice</Translation>
            </button>
          </li>
        )}

        {(isExercisePlaying || isFeedBack) && (
          <>
            <li>
              <button className="text-left" onClick={() => fancyFunctions.restart()}>
                <Translation keyName="main.menu.restart_ex">Recommencer l'exercice</Translation>
              </button>
            </li>
            <li>
              <button className="text-left" onClick={fancyFunctions.stop}>
                <Translation keyName="main.menu.quit_ex">Quitter l'exercise</Translation>
              </button>
            </li>
          </>
        )}

        {isExercisePlaying && !isItFirstExercice && (
          <li>
            <button className="text-left" onClick={goToDevicesConfig}>
              <Translation keyName="main.menu.settings">Paramètres audio et vidéo</Translation>
            </button>
          </li>
        )}

        <li>
          <button className="text-left" onClick={logout}>
            <Translation keyName="main.menu.logout">Se déconnecter de Practicio</Translation>
          </button>
        </li>
        <li>
          <a
            href="https://uploads-ssl.webflow.com/64536c788c1d11148f3105e0/649f08d1544cd9ef641e563b_Politique%20de%20confidentialit%C3%A9%20PractiVizio.pdf"
            target="_blank"
            rel="noreferrer">
            <Translation keyName="general.privacy">Politique de confidentialité</Translation>
          </a>
        </li>

        <li>
          <button className="text-left" onClick={fancyFunctions.showZendesk}>
            <Translation keyName="general.support">Contacter le support</Translation>
          </button>
        </li>
      </ul>
      <p className="centered-row mb-8 mt-auto flex items-center [&.centered-row]:max-w-half-row">
        <button
          className="mr-2 flex items-center text-left"
          onClick={toggleLanguage}
          data-testid="change-language-button">
          <img
            src={language === 'en' ? frFlag : enFlag}
            alt={language === 'en' ? 'French flag' : 'English flag'}
            className="h-6 w-6"
          />
        </button>
        <span className="text-xl">Practicio {version}</span>
      </p>
    </section>
  );

  return ReactDOM.createPortal(
    modalContent,
    document.getElementById('menu-modal-root') as HTMLElement
  );
};

export default Menu;
