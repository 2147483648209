import log from 'loglevel';
import BaseNode from './BaseNode';

export default class ExerciseNode extends BaseNode {
  // Internal values
  m_IsActive = false;
  m_IsRewindMode = false;
  m_LatestNodeActivationEventID = '';

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);
  }

  Reset() {
    this.SetActive(false);
  }

  Update() {
    if (this.IsActive()) {
      // Update
    }
  }

  /**
   * OnActivated function
   *
   * @param {Object} iActivationLink - Activation link object
   * @param {Object} iActivationLink.Source - Source of the link
   * @param {Object} iActivationLink.Source.Node - Source node
   * @param {Object} iActivationLink.Source.Port - Source port
   * @param {Object} iActivationLink.Target - Target of the link
   * @param {Object} iActivationLink.Target.Node - Target node
   * @param {Object} iActivationLink.Target.Port - Target port
   * @param {boolean} iIsRewindMode - Indicates if the node is the first activated node in rewind mode
   */
  async OnActivated(iActivationLink, iIsRewindMode = false) {
    if (!iActivationLink) {
      log.debug(this.GetIdentity() + ' has been activated with no link.');
      iActivationLink = {
        Source: {
          Node: null,
          Port: null
        },
        Target: {
          Node: this,
          Port: null
        }
      };
    } else {
      const sourceNodeName = iActivationLink.Source.Node
        ? iActivationLink.Source.Node.GetIdentity()
        : 'null';
      const sourcePortName = iActivationLink.Source.Port
        ? iActivationLink.Source.Port.Name
        : 'null';
      const targetPortName = iActivationLink.Target.Port
        ? iActivationLink.Target.Port.Name
        : 'null';

      log.debug(
        this.GetIdentity() +
          " has been activated on input port '" +
          targetPortName +
          "' by output port '" +
          sourcePortName +
          "' of node '" +
          sourceNodeName +
          "'."
      );
    }

    // Handle rewind mode
    this.SetRewindMode(iIsRewindMode);
    if (iIsRewindMode) {
      this.SetActive(false);
      return;
    }

    // Add node activation to history
    this.m_LatestNodeActivationEventID =
      await this.Graph.History.AddNodeActivation(iActivationLink);
    this.SetActive(true);
  }

  OnDeactivated() {
    this.SetActive(false);
  }

  SetActive(iActive) {
    this.m_IsActive = iActive;
  }

  SetRewindMode(iIsInRewindMode) {
    this.m_IsRewindMode = iIsInRewindMode;
  }

  IsRewindMode() {
    return this.m_IsRewindMode;
  }

  IsActive() {
    return this.m_IsActive;
  }

  Pause() {}

  Resume() {}

  FreezeSystem() {}

  UnfreezeSystem() {}

  IsPaused() {
    return this.Graph.IsPaused();
  }

  IsSystemFrozen() {
    return this.Graph.IsSystemFrozen();
  }
}
