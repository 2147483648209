import log from 'loglevel';
import { GraphEvent } from './GraphEvent';
import { GraphObserver } from './GraphObserver';

/**
 * GraphNotifier class implements the Observer pattern for graph-related events.
 * It manages a set of observers and notifies them when graph events occur.
 */
export default class GraphNotifier {
  private observers: Set<GraphObserver>;

  /**
   * Creates a new GraphNotifier instance.
   * @param observers - Optional array of initial observers
   */
  constructor(observers: GraphObserver[] = []) {
    this.observers = new Set(observers ?? []);
  }

  /**
   * Attaches a new observer to the notifier.
   * @param observer - The observer to be attached
   */
  public attachObserver(observer: GraphObserver): void {
    if (this.observers.has(observer)) {
      log.error(
        `GraphNotifier.attachObserver: Observer "${observer.name}" has been attached already.`
      );
      return;
    }
    this.observers.add(observer);
  }

  /**
   * Detaches an observer from the notifier.
   * @param observer - The observer to be detached
   */
  public detachObserver(observer: GraphObserver): void {
    if (!this.observers.delete(observer)) {
      log.error(`GraphNotifier.detachObserver: Nonexistent observer "${observer.name}".`);
    }
  }

  /**
   * Notifies all active observers about a graph event.
   * Removes inactive or invalid observers during the process.
   * @param event - The graph event to be notified about
   */
  public notify(event: GraphEvent): void {
    for (const observer of Array.from(this.observers)) {
      try {
        if (observer.isActive()) {
          observer.update(event);
        } else {
          this.observers.delete(observer);
          log.info(`GraphNotifier.notify: Removed inactive observer "${observer.name}".`);
        }
      } catch (error) {
        log.error(
          `GraphNotifier.notify: Removed invalid observer "${observer.name}", error: ${error}`
        );
        this.observers.delete(observer);
      }
    }
  }
}
