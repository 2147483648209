import log from 'loglevel';
import ExerciseNode from './ExerciseNode';
import NodePort from '../NodePort';

export default class BadWordDetector extends ExerciseNode {
  // Ports
  Input = new NodePort('Input', 'input', this);
  Speech = new NodePort('Speech', 'input', this); // Connected to a string value node to get the speech detected string
  BadWord = new NodePort('BadWord', 'output', this);
  NoBadWord = new NodePort('NoBadWord', 'output', this);

  // Dynamic Values
  InputSpeech = '';
  StartTime = null;

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);

    //log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName + ", id = " + this.ID);
  }

  async OnActivated(iActivationLink, iIsRewindMode = false) {
    await super.OnActivated(iActivationLink, iIsRewindMode);

    if (iIsRewindMode) {
      return;
    }

    this.StartTime = new Date();

    // Retrieve speech text from speech input node
    this.InputSpeech = this.GetSourceText();

    log.debug(this.GetIdentity() + " has been activated with speech = '" + this.InputSpeech + "'.");

    this.AskAPI();
  }

  GetSourceText() {
    let text = '';

    // Get the speech from the first connected node
    let speechNodes = this.Speech.GetConnectedNodes();
    if (speechNodes.length > 0) {
      text = speechNodes[0].GetStringValue();
    }

    return text;
  }

  async AskAPI() {
    // Ask BadWordsAPI
    let answer = await window.sdk.badWordsAPI().Ask(this.InputSpeech);

    log.debug(this.GetIdentity() + ' AskAPI: answer = ', answer);

    // Log to DynamoDB
    window.sdk.AnalysisTask().createOne(
      this.Graph.LastBranchingDecisionNode.DatabaseID, // Parent Branching Decision Node
      this.ID.toString(), // Node ID
      'BadWordAPI', // analyzer Engine
      answer['api-version'], // Analyzer Version
      'raw', // Analysis Status
      this.InputSpeech, // Analysis Input
      this.StartTime, // Start Time
      (new Date().getTime() - this.StartTime.getTime()).toString(), // Analysis duration (milliseconds)
      '', // Possible choices
      JSON.stringify(answer.result), // Analysis Result
      this.Graph.ExerciseID.toString() // Exercise ID
    );

    // Check if result array is not empty
    if (answer.result.length > 0) {
      log.debug(
        this.GetIdentity() + ': Bad words found. Request result: ' + JSON.stringify(answer)
      );
      this.BadWord.Activate();
    } else {
      log.debug(
        this.GetIdentity() + ': No bad words found. Request result: ' + JSON.stringify(answer)
      );
      this.NoBadWord.Activate();
    }
  }

  OnDeactivated() {
    // Stop current API call if running

    super.OnDeactivated();
  }

  PrintParameters() {}
}
