import log from 'loglevel';
import ExerciseNode from './ExerciseNode';
import NodePort from '../NodePort';
import { FEEDBACK_EVALUATIONS } from '../Solvers/DetailedFeedbacksSolver';
import { USER_ACTION_TAGS } from '../Solvers/constants';
import { GraphEventTypes } from '../GraphNotifier/GraphEvent';

export default class Act extends ExerciseNode {
  // Ports
  Input = new NodePort('Input', 'input', this);
  Output = new NodePort('Output', 'output', this);

  // Parameters
  NodeName = '';
  ActNumber = 0;

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);

    this.NodeName = iProperties.NodeName;
    this.ActNumber = iProperties.ActNumber;

    //log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName + ", id = " + this.ID + ", iNodeName = " + this.NodeName + ".");
  }

  async OnActivated(iActivationLink, iIsRewindMode = false) {
    await super.OnActivated(iActivationLink, iIsRewindMode);

    this.Graph.SetCurrentActNode(this);

    if (iIsRewindMode) {
      return;
    }
    this.Graph.Notifier.notify({
      type: GraphEventTypes.ActCompletion,
      content: {
        id: this.NodeName,
        displayedName: this.NodeName,
        inProgress: true
      }
    });

    this.ActivateOutput();
  }

  ActivateOutput() {
    log.debug(this.GetIdentity() + "' activating output.");

    this.SetActive(false);

    this.Output.Activate();
  }

  PrintParameters() {
    //log.debug("Act: NodeName = " + this.NodeName + ".");
  }

  OnActEvaluation() {
    const evaluation = this.SolveEvaluation();

    this.Graph.Notifier.notify({
      type: GraphEventTypes.ActCompletion,
      content: {
        id: this.NodeName,
        displayedName: this.NodeName,
        evaluation: evaluation,
        inProgress: false
      }
    });

    this.Graph.History.AddActCompletionEvent(this.ID, this.NodeName, evaluation);
  }

  SolveEvaluation() {
    const userActionFeedbackEvents = this.Graph.History.GetUserActionsFeedbacksByActName(
      this.NodeName
    );

    const userActionfeedbacks = userActionFeedbackEvents.map((event) => {
      const uaf = this.Graph.GetFullUserActionFeedbackData(
        event.Content.UserActionFeedbackID,
        event.Content.NodeID
      );
      uaf.IsMissedOpportunity = event.Content.IsMissedOpportunity;
      return uaf;
    });

    // Sort by priority and get highest priority UAF
    const highestPriorityFeedback = userActionfeedbacks.sort(
      (a, b) => b.PriorityRank - a.PriorityRank
    )[0];

    if (highestPriorityFeedback.Tags.includes(USER_ACTION_TAGS.LIMIT_CASE)) {
      return FEEDBACK_EVALUATIONS.FAIL;
    }

    if (
      highestPriorityFeedback.Tags.includes(USER_ACTION_TAGS.BAD_ACTION) ||
      highestPriorityFeedback.IsMissedOpportunity
    ) {
      return FEEDBACK_EVALUATIONS.BAD;
    }

    return FEEDBACK_EVALUATIONS.GOOD;
  }
}
