import React, { useContext, useEffect } from 'react';
import PauseMenu from '@/components/PauseMenu/PauseMenu';
import log from 'loglevel';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Utils from '@/AppClasses/Utils/Utils';
import { Translation } from '@/components/Utilities/Translation';
import { ExerciseContext } from '../../ExerciseContext';
import Mosaique from '../Scenario/Mosaique';
import OneToOne from '../Scenario/OneToOne';
import FeedbacksLoader from '@/components/feedback/FeedbacksLoader';
import LegacyUserActionsToast from '../LegacyUserActionsToast';
import { useExerciseNavigation } from '@/hooks/useExerciseNavigation';
import { usePingBackend } from '@/hooks/usePingBackend';
const Scenario = () => {
  const {
    ExerciseGraph,
    ExerciseID,
    jsonGraph,
    cache,
    exerciseStarted,
    setExerciseStarted,
    isFeedbacksLoading,
    isSttFailedModalOpen,
    setIsSttFailedModalOpen
  } = useContext(ExerciseContext);

  const { hardRestartExercise, quitExercise } = useExerciseNavigation(ExerciseID);
  const [pingStatus, pingControls] = usePingBackend();

  useEffect(() => {
    if (!pingStatus.healthy) {
      ExerciseGraph.Pause(false);

      window.sdk.usersActivity().createOne('pingNotHealthy', {
        ExerciseSessionID: ExerciseGraph.CurrentExerciseSessionID,
        Latency: pingStatus.latency
      });

      pingControls.stop();
    }
  }, [pingStatus.healthy]);

  useEffect(() => {
    startScenario();
  }, []);

  const stopExercise = () => {
    setIsSttFailedModalOpen(false);
    quitExercise({});
  };

  const resetExercise = async () => {
    hardRestartExercise();
  };

  const startScenario = async () => {
    log.debug('Exercise.startScenario');

    while (window.sdk.cacheManager().getTotalProgressionInPercent() < 100) {
      log.debug('Exercise.startScenario: Waiting for all videos caching... ');
      await Utils.Sleep(1000);
    }

    log.debug('Exercise.startScenario: Ready, starting!');

    await start();

    // Prevent user interactions if the customization value is set to true or forced on exercise 1
    if (
      ExerciseID === '1' ||
      (jsonGraph.CustomizationValues && jsonGraph.CustomizationValues.ForbiddenInteractionWarning)
    ) {
      window.sdk.forbiddenInteractionWarning().init(document.querySelector('.exercise__main'), {
        distanceMax: 50,
        timeMax: 500,
        calibrationPhaseDuration: 1000,
        detectionWindowDuration: 1000,
        soundVariationAllowed: 0.05,
        timeResumeAfterWarning: 15000,
        allowedClickZones: ['#header', '#secondary', '.debug', '.exercise-bottom-bar']
      });
    }
    setExerciseStarted(true);
  };

  const start = async () => {
    // Start initializations
    log.debug('+++ Exercise Start +++');
    document.body.classList.add('exercise--start');

    // Start the exercise graph
    await ExerciseGraph.Start();
  };

  const PauseMenuModal = () => {
    const shouldShowModal = isSttFailedModalOpen || !pingStatus.healthy;
    const modalReason = isSttFailedModalOpen ? 'sttFailed' : 'pingNotHealthy';

    if (!shouldShowModal) {
      return null;
    }

    return (
      <PauseMenu
        reason={modalReason}
        fancyFunctions={{
          restart: resetExercise,
          stop: stopExercise
        }}
      />
    );
  };

  if (cache.totalProgression < 100) {
    return (
      <>
        <p
          className="mx-auto max-w-half-row text-center text-base"
          data-testid="scenario-loading-text">
          <Translation keyName="scenario.wait">
            Merci de patienter le temps que nous chargions les données de cet exercice...
          </Translation>
        </p>
        <ProgressBar
          className="mx-auto max-w-half-row"
          tabIndex="0"
          animated
          now={cache.totalProgression}
          variant="warning"
          label={`${cache.totalProgression}%`}
        />
      </>
    );
  }

  if (isFeedbacksLoading) {
    return <FeedbacksLoader />;
  }

  if (exerciseStarted) {
    if (jsonGraph.ExerciseSettings && jsonGraph.ExerciseSettings.PlayMode === 'one_to_one') {
      return (
        <>
          <OneToOne />
          <PauseMenuModal />
        </>
      );
    }

    return (
      <>
        <LegacyUserActionsToast />
        <Mosaique />
        <PauseMenuModal />
      </>
    );
  }

  return null;
};

export default Scenario;
