import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import EditPassword from '@/pages/auth/EditPassword/EditPassword';
import Login from '@/pages/auth/Login/Login';
import PasswordLost from '@/pages/auth/PasswordLost/PasswordLost';
import Register from '@/pages/auth/Register/Register';
import RegisterPending from '@/pages/auth/RegisterPending/RegisterPending';

const AuthRouting = () => {
  const [isLoading, setIsLoading]: [boolean, Dispatch<SetStateAction<boolean>>] = useState(false);

  useEffect(() => {
    window.sdk.event().on('fetchStarted', () => setIsLoading(true));
    window.sdk.event().on('fetchFinished', () => setIsLoading(false));
    window.sdk.event().on('loaderHide', () => setIsLoading(false));

    return () => {
      window.sdk.event().removeListener('fetchStarted', () => setIsLoading(true));
      window.sdk.event().removeListener('fetchFinished', () => setIsLoading(true));
      window.sdk.event().removeListener('loaderHide', () => setIsLoading(false));
    };
  });

  /*TODO: remove after full tsx refacto */
  const TypedRegister = Register as React.ComponentType<{ loading: boolean }>;
  const TypedRegisterPending = RegisterPending as React.ComponentType<{ loading: boolean }>;
  const TypedLogin = Login as React.ComponentType<{ loading: boolean }>;
  const TypedPasswordLost = PasswordLost as React.ComponentType<{ loading: boolean }>;

  return (
    <Routes>
      <Route path="/" element={<TypedLogin loading={isLoading} />} />
      <Route path="/register" element={<TypedRegister loading={isLoading} />} />
      <Route path="/register-pending" element={<TypedRegisterPending loading={isLoading} />} />
      <Route path="/password-lost" element={<TypedPasswordLost loading={isLoading} />} />
      <Route path="/edit-password" element={<EditPassword />} />
      <Route
        path="*"
        element={<Navigate to={'/?redirect_url=' + document.location.href} replace />}
      />
    </Routes>
  );
};

export default AuthRouting;
