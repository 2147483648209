import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Exercise from '@/pages/Exercise/Exercise';
import OldFeedbackWrapper from '@/pages/LegacyFeedback/OldFeedbackWrapper';
import TestBot from '@/pages/test/TestBot';
import TestDevice from '@/pages/test/TestDevice';

// import UIKitDemo from '@/pages/UIKitDemo/UIKitDemo';
import Welcome from '@/pages/Welcome/Welcome';
import Feedback from './Feedback/Feedback';
import { StepContext } from '@UIKit/Stepper/Stepper';

const MainRouting = () => {
  const [activeStepId, setActiveStepId] = useState<string>('ACT-0');

  return (
    <Routes>
      <Route path="/" element={<Welcome />} />
      <Route path="/exercise/:id/:step" element={<Exercise />} />
      <Route path="/exercise/:id" element={<Exercise />} />
      <Route path="/exercise/:id/:ExerciseSessionID/rerun/:RerunNodeID" element={<Exercise />} />
      <Route path="/feedback/:idExercise/:idExerciseSession" element={<OldFeedbackWrapper />} />
      <Route
        path="/feedback/:exerciseID/:exerciseSessionID/global"
        element={
          <StepContext.Provider value={{ activeStepId, setActiveStepId }}>
            <Feedback />
          </StepContext.Provider>
        }
      />
      <Route path="/test/bot" element={<TestBot />} />
      <Route path="/test/device" element={<TestDevice />} />
    </Routes>
  );
};

export default MainRouting;
