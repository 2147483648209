import { GraphEventTypes } from '@/AppClasses/ExerciseScenario/GraphNotifier/GraphEvent';
import {
  GraphObserver,
  GraphObserverUpdateCallback
} from '@/AppClasses/ExerciseScenario/GraphNotifier/GraphObserver';

export function useUserActionToastsObserver(
  updateCallback: GraphObserverUpdateCallback
): GraphObserver {
  return new GraphObserver(
    'UserActionToastsObserver',
    GraphEventTypes.UserActionToasts,
    updateCallback
  );
}
