import React, { Dispatch, RefObject, SetStateAction, useContext, useState, useEffect } from 'react';
import { DetailedFeedbackModel } from '@/models/DetailedFeedbackModel';
import ExerciseControls from './ExerciseControls';
import StepNavButtons from './StepNavButtons';

export type StepModel = Omit<DetailedFeedbackModel, 'userActions'> & {
  isDisabled?: boolean;
  inProgress?: boolean;
};

type StepperProps = {
  className?: string;
  initialSteps?: DetailedFeedbackModel[];
  isExerciseRunning?: boolean;
  isTabMode?: boolean;
  callback?: () => void;
};

export const StepContext = React.createContext({
  activeStepId: 'ACT-0',
  setActiveStepId: () => {}
} as { activeStepId: string; setActiveStepId: Dispatch<SetStateAction<string>> });

const Stepper = ({
  initialSteps,
  className = '',
  isExerciseRunning,
  isTabMode,
  callback
}: StepperProps) => {
  const { activeStepId, setActiveStepId } = useContext(StepContext);
  const [selectedSteps, setSelectedSteps] = useState<Set<string>>(new Set());
  const [firstStepPulsating, setFirstStepPulsating] = useState(true);

  const evaluatedSteps: StepModel[] | undefined = initialSteps?.filter(
    (step: StepModel) => step.evaluation
  );

  useEffect(() => {
    if (
      isTabMode &&
      evaluatedSteps &&
      evaluatedSteps.length > 0 &&
      activeStepId === evaluatedSteps[0].id
    ) {
      setFirstStepPulsating(false);
    }
  }, [isTabMode, activeStepId, evaluatedSteps]);

  if (!evaluatedSteps) {
    return null;
  }

  const [tabRefs] = useState<RefObject<HTMLButtonElement>[]>(
    evaluatedSteps.map(() => React.createRef<HTMLButtonElement>())
  );

  const getActiveEvaluatedStepIndex = () =>
    evaluatedSteps.findIndex((step: StepModel) => step.id === activeStepId);

  const selectStep = (id: string) => (event: React.MouseEvent) => {
    event.preventDefault();
    if (callback) {
      callback();
    }
    setActiveStepId(id);
    setSelectedSteps((prev) => {
      const newSet = new Set(prev);
      newSet.add(id);
      return newSet;
    });
  };

  const onKeydown = (event: React.KeyboardEvent) => {
    const tabsLength: number = evaluatedSteps.length;
    if (!tabsLength) {
      return;
    }

    const activeTabIndex: number = getActiveEvaluatedStepIndex();

    let timer: undefined | ReturnType<typeof setTimeout>;

    const updateStep = (newIndex: number) => {
      const newStepId = evaluatedSteps[newIndex].id;
      setActiveStepId(newStepId);
      setSelectedSteps((prev) => {
        const newSet = new Set(prev);
        newSet.add(newStepId);
        return newSet;
      });
      // Removed setIsPulsating(false) as we no longer use this state
      timer = setTimeout(() => tabRefs[newIndex].current?.focus());
    };

    switch (event.key) {
      case 'ArrowRight': {
        event.preventDefault();
        if (activeTabIndex < tabsLength - 1) {
          updateStep(activeTabIndex + 1);
        }
        break;
      }
      case 'ArrowLeft': {
        event.preventDefault();
        if (activeTabIndex > 0) {
          updateStep(activeTabIndex - 1);
        }
        break;
      }
      default: {
        break;
      }
    }

    return () => clearTimeout(timer);
  };

  const StepListItem = ({ step }: { step: StepModel }) => {
    const inProgress: JSX.Element = (
      <div className="w-full border-t-5 pt-2 text-left text-light-brand label">
        {step.displayedName}
      </div>
    );
    const undiscovered: JSX.Element = (
      <div className="w-full border-t-5 pt-2 text-left text-light-brand opacity-20 label">
        {step.displayedName}
      </div>
    );

    const evaluated = (counter: number) => {
      const className: string =
        'w-full border-t-5 border-[var(--evaluation)] pt-2 text-left text-light-brand label';

      if (isExerciseRunning) {
        return <div className={className}>{step.displayedName}</div>;
      }

      return (
        <button
          id={'tab-' + step.id}
          ref={tabRefs[counter]}
          role="tab"
          aria-controls={'panel-' + step.id}
          aria-selected={activeStepId === step.id}
          tabIndex={activeStepId === step.id ? 0 : -1}
          className={`${
            !isTabMode
              ? ''
              : 'transition-opacity disabled:opacity-40 aria-[selected=false]:opacity-40 aria-[selected=false]:hover:opacity-100'
          } ${className} ${
            !isExerciseRunning &&
            (step.evaluation === 'FAIL' || step.evaluation === 'BAD') &&
            !selectedSteps.has(step.id) &&
            (step.id !== evaluatedSteps[0]?.id || firstStepPulsating) &&
            (step.id === evaluatedSteps[0]?.id || step.id !== activeStepId)
              ? 'shake-vertical'
              : ''
          }`}
          onClick={selectStep(step.id)}
          disabled={step.isDisabled}
          onKeyDown={onKeydown}
          onFocus={() => selectStep(step.id)}
          data-testid={`step-${step.displayedName}`}>
          {step.displayedName}
        </button>
      );
    };

    const stepTestId = `step-${step.displayedName?.replace(/\s+/g, '-')}${
      step.inProgress ? '-IN-PROGRESS' : step.evaluation ? `-${step.evaluation}` : ''
    }`;

    return (
      <li
        className="flex-1"
        data-testid={stepTestId}
        {...(step.evaluation && {
          style: {
            '--evaluation': step?.isAdvice
              ? `var(--advice-color)`
              : `var(--${step.evaluation.toLowerCase()}-color)`
          } as React.CSSProperties
        })}>
        {step.inProgress
          ? inProgress
          : step.isUndiscovered || !step.evaluation
            ? undiscovered
            : evaluated(evaluatedSteps.indexOf(step))}
      </li>
    );
  };

  return (
    <nav className={`${className} -mt-4 flex flex-wrap items-center overflow-hidden`}>
      <ul className="mt-4 flex flex-1 space-x-4" role="tablist" aria-orientation="horizontal">
        {initialSteps?.map((step) => <StepListItem key={step.id} step={step} />)}
      </ul>

      <p
        className={`mx-auto mt-4 flex ${
          !isExerciseRunning && !isTabMode ? '[&_button]:-z-10 [&_button]:opacity-0' : ''
        } `}>
        {!isExerciseRunning && (
          <StepNavButtons
            steps={initialSteps || []}
            callback={callback}
            setSelectedSteps={setSelectedSteps}
          />
        )}
      </p>
    </nav>
  );
};

export default Stepper;
