import log from 'loglevel';
import ExerciseNode from './ExerciseNode';
import NodePort from '../NodePort';

export default class Scene extends ExerciseNode {
  // Ports
  Input = new NodePort('Input', 'input', this);
  Output = new NodePort('Output', 'output', this);

  // Parameters
  NodeName = '';
  ActNumber = null;
  SceneNumber = null;
  UnlockedTrophy = null;
  Summary = null;
  Context = null;
  Objectives = null;
  ShouldReplayPreviousVideo = null;

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);

    this.NodeName = iProperties.NodeName;
    this.ActNumber = iProperties.ActNumber || null;
    this.SceneNumber = iProperties.SceneNumber || null;
    this.UnlockedTrophy = iProperties.UnlockedTrophy || null;
    this.Summary = iProperties.Summary || null;
    this.Context = iProperties.Context || null;
    this.Objectives = iProperties.Objectives || null;
    this.ShouldReplayPreviousVideo = iProperties.ShouldReplayPreviousVideo || false;
  }

  async OnActivated(iActivationLink, iIsRewindMode = false) {
    await super.OnActivated(iActivationLink, iIsRewindMode);

    this.Graph.CurrentSceneNode = this;

    if (iIsRewindMode) {
      return;
    }

    this.Graph.History.AddSceneActivation(
      this.ID,
      this.NodeName,
      this.ActNumber,
      this.SceneNumber,
      this.Summary,
      this.Context,
      this.Objectives,
      this.ShouldReplayPreviousVideo
    );

    if (this.UnlockedTrophy) {
      this.Graph.History.AddUnlockedTrophyEvent(this.ID, this.UnlockedTrophy);
    }

    this.ActivateOutput();
  }

  ActivateOutput() {
    log.debug(this.GetIdentity() + "' activating output.");

    this.SetActive(false);

    this.Output.Activate();
  }
}
