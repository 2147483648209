import log from 'loglevel';
import ExerciseNode from './ExerciseNode';
import NodePort from '../NodePort';

export default class StopExercise extends ExerciseNode {
  // Ports
  Stop = new NodePort('Stop', 'input', this);

  // Parameters
  StopType = null;
  // Internal values

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);

    this.StopType = iProperties.StopType || null;
  }

  async OnActivated(iActivationLink, iIsRewindMode = false) {
    await super.OnActivated(iActivationLink, iIsRewindMode);

    if (iIsRewindMode) {
      return;
    }

    this.Graph.History.AddStopEvent(this.ID, this.StopType);
    // Stop the current exercise
    this.Graph.Stop();

    this.SetActive(false);
  }

  PrintParameters() {
    //log.debug("BotName = " + this.BotName + ", DefaultLoop = " + this.DefaultLoop + ", PreferredPosition = " + this.PreferredPosition);
  }
}
