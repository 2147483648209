import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslate } from '@tolgee/react';
import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { PasswordValidationIndicator } from '@/components/Utilities/PasswordValidationIndicator';
import { Translation } from '@/components/Utilities/Translation';
import log from 'loglevel';

const Login = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslate();

  const [email, setEmail] = useState(location.state ? location.state.email : '');
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [failed, setFailed] = useState(false);
  const [info, setInfo] = useState({});
  const [isEmailChecked, setIsEmailChecked] = useState(false);
  const [valided, setValided] = useState({
    number: false,
    special: false,
    length: false,
    min: false,
    maj: false,
    email: true,
    password: true
  });

  const updateEmail = (value) => {
    setValided((prev) => ({
      ...prev,
      email: value.length === 0 ? true : validateEmail(value)
    }));
    setEmail(value);
    setFailed(false);
    setIsEmailChecked(false);
  };

  const checkEmail = async () => {
    if (!email || !valided.email) {
      setValided((prev) => ({ ...prev, email: false }));
      return;
    }

    try {
      const response = await window.sdk.fetchInternalAPI().run('/auth/check-email', {
        method: 'POST',
        body: JSON.stringify({ email })
      });

      if (!response.hasValidLicense) {
        setFailed(true);
        setInfo({
          message: (
            <Translation keyName="login.no_license">
              Vous ne semblez pas avoir de licence pour accéder à l'application actuellement
            </Translation>
          )
        });
        return;
      }

      if (response.userExists && !response.hasValidatedEmail) {
        // Only redirect to email confirmation if the user exists but hasn't validated email
        navigate('/register', {
          state: {
            email: email,
            password: password,
            unconfirmedUser: true
          }
        });
        return;
      }

      if (response.isActive) {
        setIsEmailChecked(true);
      } else {
        // Redirect to register page with email and redirect_url
        const redirect_url = location.state?.redirect_url || window.sdk.getParam('redirect_url');
        const registerPath = redirect_url
          ? `/register?redirect_url=${encodeURIComponent(redirect_url)}`
          : '/register';
        navigate(registerPath, {
          state: {
            email: email,
            isNewUser: true
          }
        });
      }
    } catch (e) {
      setFailed(true);
      setInfo({ message: e.message });
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const submit = async () => {
    if (!isEmailChecked) {
      await checkEmail();
      return;
    }

    let isValid = true;
    if (!password || !valided.number || !valided.special || !valided.min || !valided.maj) {
      setValided((prev) => ({ ...prev, password: false }));
      isValid = false;
    }
    if (!isValid) return false;

    try {
      const res = await window.sdk.user().login({
        username: email,
        password: password,
        newpassword: password,
        name: email,
        redirect_url: location.state?.redirect_url
      });
      if (res.state === 'ERROR') {
        setFailed(true);
        if (res.error.code === 'UserNotConfirmedException') {
          // Redirect to Register page for confirmation
          navigate('/register', {
            state: {
              email: email,
              password: password,
              unconfirmedUser: true
            }
          });
          return;
        } else {
          setInfo(res.error);
        }
      } else if (res.state === 'success') {
        window.location.href = res.redirect_url || '/';
      }
    } catch (e) {
      log.error('Login error:', e);
      setFailed(true);
      let errorMessage;
      try {
        const errorObj = JSON.parse(e.message);
        const bodyObj = JSON.parse(errorObj.body);
        if (bodyObj.error && bodyObj.error.name === 'NoValidLicense') {
          setInfo({
            message: (
              <Translation keyName="login.no_license">
                Vous ne semblez pas avoir de licence pour accéder à l'application actuellement
              </Translation>
            )
          });
          return;
        }
        errorMessage = bodyObj.message || e.message;
      } catch {
        errorMessage = e.message;
      }
      setInfo({ message: errorMessage });
    } finally {
      window.sdk.event().emit('loaderHide');
    }
  };

  const updatePassword = (value) => {
    setValided((prev) => ({
      ...prev,
      password: true,
      number: value.search(/.*\d/) >= 0,
      special: /[^a-zA-Z0-9]/.test(value),
      length: value.length >= 8,
      min: value.search(/.*[a-z]/) >= 0,
      maj: value.search(/.*[A-Z]/) >= 0
    }));
    setPassword(value);
    setFailed(false);
  };

  const togglePassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <>
      <h2 className="title">
        <Translation keyName="login.welcome">
          Bienvenue, connectez-vous pour accéder à vos simulations
        </Translation>
      </h2>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}>
        {failed && (
          <Alert className="alert mt-4" variant="danger">
            {info.message}
          </Alert>
        )}

        <div>
          <Form.Group className="mt-4" controlId="email">
            <Form.Label className="pl-4 label">
              <Translation keyName="general.email">Email</Translation>
            </Form.Label>
            {isEmailChecked ? (
              <p className="mt-2 pl-4">{email}</p>
            ) : (
              <Form.Control
                type="email"
                name="email"
                autoComplete="email"
                placeholder={t('login.your_email', 'Votre adresse email')}
                onChange={(e) => updateEmail(e.target.value)}
                value={email}
                isInvalid={!valided.email}
                required
                data-testid="register-email-input"
              />
            )}
          </Form.Group>

          <Form.Group 
            className={`mt-4 ${!isEmailChecked ? 'hidden' : ''}`} 
            controlId="password"
          >
            <Form.Label className="pl-4 label">
              <Translation keyName="general.password">Mot de passe</Translation>
            </Form.Label>
            <div className="relative [&_.form-control]:pr-10">
              <Form.Control
                data-testid="login-password-input"
                type={showPassword ? 'text' : 'password'}
                name="password"
                autoComplete="current-password"
                placeholder={t('login.your_password', 'Votre mot de passe')}
                onChange={(e) => updatePassword(e.target.value)}
                value={password}
                isInvalid={!valided.password}
                required
              />
              <button
                type="button"
                className="absolute right-4 top-1/2 -translate-y-1/2"
                onClick={togglePassword}>
                <FontAwesomeIcon icon={!showPassword ? faEye : faEyeSlash} />
              </button>
            </div>
            <PasswordValidationIndicator className="mt-3" valided={valided} />
          </Form.Group>

          {/* Hidden password field for password managers when on email step */}
          {!isEmailChecked && (
            <Form.Control
              type="password"
              name="password"
              autoComplete="current-password"
              className="hidden"
              tabIndex={-1}
              aria-hidden="true"
            />
          )}

          <button
            className="cta cta--accent mt-4"
            data-testid="login-submit-btn"
            type="submit"
            disabled={props.loading}
            onClick={submit}>
            <Translation keyName={isEmailChecked ? 'general.login' : 'general.validate'}>
              {isEmailChecked ? 'Connexion' : 'Valider'}
            </Translation>
          </button>

          <p className="mt-4">
            <LinkContainer to="/password-lost" state={{ email: email }}>
              <a className="link text-xs" data-testid="forgot-password-button">
                <Translation keyName="login.forgot_pwd">Mot de passe oublié ?</Translation>
              </a>
            </LinkContainer>
          </p>
        </div>
      </form>
    </>
  );
};

export default Login;
