import log from 'loglevel';
import Utils from '../../Utils/Utils';
import ExerciseNode from './ExerciseNode';
import NodePort from '../NodePort';

export default class BranchingDecision extends ExerciseNode {
  // Ports
  Input = new NodePort('Input', 'input', this);
  Analysis = new NodePort('Analysis', 'output', this);

  // Parameters
  Branches = [];

  // Dynamic values
  StartTime = null;
  DatabaseID = '';

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);

    iProperties.Branches.forEach((branch) => {
      //log.debug(this.GetIdentity() + " constructor: Adding dynamic branch '" + branch.Name + "'.");

      let newBranch = new Branch(branch.ID, branch.Name);
      this.Branches.push(newBranch);

      this[newBranch.GetInputPortName()] = new NodePort(
        newBranch.GetInputPortName(),
        'input',
        this
      );
      this[newBranch.GetOutputPortName()] = new NodePort(
        newBranch.GetOutputPortName(),
        'output',
        this
      );
    });

    //log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName + ", id = " + this.ID + ", branches count = " + this.Branches.length + ".");
  }

  async OnActivated(iActivationLink, iIsRewindMode = false) {
    await super.OnActivated(iActivationLink, iIsRewindMode);

    if (iIsRewindMode) {
      return;
    }

    // If first main activation, start the node
    if (!iActivationLink.Target.Port || iActivationLink.Target.Port.Name === this.Input.Name) {
      this.StartTime = new Date();

      // Disable pause button at start
      window.sdk.event().emit('disablePauseButton');

      // Save this node as the last branching decision node
      this.Graph.LastBranchingDecisionNode = this;
      this.Graph.IncrementBranchingDecisionsActivations();

      // Get the bot's video names for each branch
      this.Branches.forEach((branch) => {
        branch.VideoName = Utils.GetNextBotVideoAfterPort(
          this[branch.GetOutputPortName()]
        )?.VideoName;
      });

      // Log initialized BranchingDecision to DynamoDB
      let branchingDecision = await window.sdk
        .BranchingDecision()
        .createOne(
          this.Graph.CurrentExerciseSessionID,
          this.StartTime,
          'initialized',
          this.ID.toString(),
          'true',
          JSON.stringify(this.Branches)
        );
      this.DatabaseID = branchingDecision.ID;
      log.debug(this.GetIdentity() + '.OnActivated: BranchingDecisionID = ' + this.DatabaseID);

      // Activate Analysis process
      this.Analysis.Activate();
    }
    // If received a branching decision, activate the chosen branch
    else {
      let chosenBranch = this.GetBranchFromInput(iActivationLink.Target.Port);

      log.debug(
        this.GetIdentity() +
          ' has been activated on a choice port (' +
          iActivationLink.Target.Port.Name +
          '), chosen branch = ' +
          (chosenBranch ? chosenBranch.Name : 'NO BRANCH!!!') +
          '.'
      );

      this.OnBranchChosen(chosenBranch);
    }
  }

  GetBranchFromInput(iInputPort) {
    return this.Branches.find((branch) => branch.GetInputPortName() === iInputPort.Name);
  }

  OnBranchChosen(iChosenBranch) {
    const chosenBranch = JSON.stringify(iChosenBranch);
    // Log to DynamoDB
    window.sdk
      .BranchingDecision()
      .updateItem(this.Graph.CurrentExerciseSessionID, this.DatabaseID, {
        DecisionStatus: 'raw',
        ChosenBranch: chosenBranch
      });

    let chosenBranchPort = this.GetPortByName(iChosenBranch.GetOutputPortName());

    if (chosenBranchPort) {
      chosenBranchPort.Activate();
    }

    this.Graph.History.AddBranchingDecisionResult(
      this.ID,
      chosenBranch,
      this.Graph.GetCurrentSceneName(),
      this.Graph.GetCurrentSceneNodeID(),
      this.DatabaseID
    );

    this.Reset();
  }

  Reset() {
    super.Reset();

    // Re-enable pause button when finished
    window.sdk.event().emit('enablePauseButton');
  }

  PrintParameters() {
    //log.debug("ValueBool: ID = " + this.ID + ", Name = " + this.Name + ".");
  }

  //////////////////////////
  // Test functions
  //////////////////////////

  TestExecute(iActivationLink, iTestReport) {
    // Start the test
    if (!iActivationLink.Target.Port || iActivationLink.Target.Port.Name === this.Input.Name) {
      // Test-activate Analysis process
      this.Analysis.TestActivateAllConnections(iTestReport);
    } else {
      let chosenBranch = this.GetBranchFromInput(iActivationLink.Target.Port);

      // Fill the test report
      iTestReport['ChosenBranch'] = chosenBranch;
    }
  }
}

class Branch {
  ID = -1;
  Name = '';

  constructor(iID, iName) {
    this.ID = iID;
    this.Name = iName;
  }

  GetInputPortName() {
    return 'iBranch' + this.ID;
  }

  GetOutputPortName() {
    return 'oBranch' + this.ID;
  }

  ToString() {
    return (
      '{' +
      "\n  Name: '" +
      this.Name +
      "'" +
      "\n  InputPort: '" +
      this.GetInputPortName() +
      "'" +
      "\n  OutputPort: '" +
      this.GetOutputPortName() +
      "'" +
      '\n}'
    );
  }
}
