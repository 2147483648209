import log from 'loglevel';
import ExerciseNode from './ExerciseNode';
import NodePort from '../NodePort';
import ParticipantsModule from '../../Participants/ParticipantsModule';

export default class BotConnection extends ExerciseNode {
  // Ports
  Input = new NodePort('Input', 'input', this);
  Output = new NodePort('Output', 'output', this);

  // Parameters
  BotName = '';
  BotGender = '';
  DefaultLoop = '';
  PreferredPosition = 0;

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);

    this.BotName = iProperties.BotName;
    this.BotGender = iProperties.BotGender;
    this.DefaultLoop = iProperties.DefaultLoop;
    this.PreferredPosition = iProperties.PreferredPosition;
  }

  async OnActivated(iActivationLink, iIsRewindMode = false) {
    await super.OnActivated(iActivationLink, iIsRewindMode);

    log.debug(this.GetIdentity() + " starting Bot '" + this.BotName + "'...");

    if (!ParticipantsModule.Instance) {
      log.debug('BotsModule.Instance === null');
      return;
    }

    // Start and gives a callback when finished
    ParticipantsModule.Instance.StartABot(this.BotName, this.BotGender, this.DefaultLoop, () =>
      this.OnActionFinished()
    );

    if (iIsRewindMode) {
      return;
    }

    this.Graph.History.AddEvent('BotConnection', {
      NodeID: this.ID,
      Character: this.BotName,
      LoopVideo: this.DefaultLoop
    });
  }

  OnActionFinished() {
    log.debug(this.GetIdentity() + ": Bot '" + this.BotName + "' finished its connection action.");

    this.ActivateOutput();
  }

  ActivateOutput() {
    log.debug(this.GetIdentity() + "' activating output.");

    this.SetActive(false);

    this.Output.Activate();
  }

  PrintParameters() {
    log.debug(
      'BotName = ' +
        this.BotName +
        ', DefaultLoop = ' +
        this.DefaultLoop +
        ', PreferredPosition = ' +
        this.PreferredPosition
    );
  }
}
