import React from 'react';
import { Translation } from '../Utilities/Translation';

const FeedbacksLoader: React.FC = () => {
  return (
    <>
      <p className="mx-auto max-w-half-row text-center title">
        <Translation keyName="feedbacks.loader.preparationMessage">
          On prépare vos feedbacks...
        </Translation>
      </p>
      <p className="mx-auto max-w-half-row text-center title">
        <Translation keyName="feedbacks.loader.almostReadyMessage">
          Encore un petit instant, c'est bientôt prêt !
        </Translation>
      </p>
      <div className="mt-4 items-center justify-center">
        <div className="spinner-loader dark mx-auto block"></div>
      </div>
    </>
  );
};

export default FeedbacksLoader;
