import log from 'loglevel';
import ExerciseNode from './ExerciseNode';
import NodePort from '../NodePort';

export default class TrackedEvent extends ExerciseNode {
  // Ports
  Input = new NodePort('Input', 'input', this);

  // Parameters
  Name = '';

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);

    this.Name = iProperties.Name;
    //log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName + ", id = " + this.ID + ", name = " + this.Name);
  }

  async OnActivated(iActivationLink, iIsRewindMode = false) {
    await super.OnActivated(iActivationLink, iIsRewindMode);

    if (iIsRewindMode) {
      return;
    }

    this.Graph.IncrementIntValue(this.Name);
    this.SetActive(false);
  }

  PrintParameters() {
    //log.debug("ValueBool: ID = " + this.ID + ", Name = " + this.Name + ".");
  }
}
