import log from 'loglevel';
import ExerciseNode from './ExerciseNode';
import NodePort from '../NodePort';

export default class RandomBranch extends ExerciseNode {
  // Ports
  Input = new NodePort('Input', 'input', this);

  // Parameters
  Branches = [];

  // Dynamic values

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);

    iProperties.Branches.forEach((branch) => {
      log.debug(this.GetIdentity() + " constructor: Adding dynamic branch '" + branch.Name + "'.");

      let newBranch = new Branch(branch.ID, branch.Name, branch.Probability);
      this.Branches.push(newBranch);

      this[newBranch.GetOutputPortName()] = new NodePort(
        newBranch.GetOutputPortName(),
        'output',
        this
      );
    });

    // Normalize the branches probas
    log.debug(
      this.GetIdentity() +
        ' probas before normalization = ' +
        this.Branches.map((branch) => branch.Probability)
    );
    let probasSum = this.Branches.reduce((total, branch) => total + branch.Probability, 0);
    this.Branches.forEach((branch) => (branch.Probability /= probasSum));
    log.debug(
      this.GetIdentity() +
        ' probas after normalization = ' +
        this.Branches.map((branch) => branch.Probability)
    );

    log.debug(
      this.GetIdentity() +
        ' constructor: graph = ' +
        this.Graph.ExerciseName +
        ', id = ' +
        this.ID +
        ', branches count = ' +
        this.Branches.length +
        '.'
    );
  }

  async OnActivated(iActivationLink, iIsRewindMode = false) {
    await super.OnActivated(iActivationLink, iIsRewindMode);

    if (iIsRewindMode) {
      return;
    }

    // Randomly choose among the available branches, considering their probabilities and using the graph random seed

    // Choose the branch with a random method using the seed
    //let seedRandom = new Math.seedrandom(seed);
    let chosenBranch = null;
    let randomValue = this.Graph.GenerateRandomValue(); // Instead of Math.random() to be able to set the seed
    log.debug(this.GetIdentity() + ' randomValue = ' + randomValue);

    // Choose the branch
    let probasSum = 0;
    for (let i = 0; i < this.Branches.length; i++) {
      probasSum += this.Branches[i].Probability;
      if (randomValue <= probasSum) {
        chosenBranch = this.Branches[i];
        break;
      }
    }
    log.debug(this.GetIdentity() + ' chosenBranchID = ' + chosenBranch.ID);

    // Activate the chosen branch
    this[chosenBranch.GetOutputPortName()].Activate();
  }
}

class Branch {
  ID = -1;
  Name = '';
  Probability = 1;

  constructor(iID, iName, iProbability) {
    this.ID = iID;
    this.Name = iName;
    this.Probability = iProbability;
  }

  GetOutputPortName() {
    return 'Branch' + this.ID;
  }

  ToString() {
    return (
      '{' +
      "\n  Name: '" +
      this.Name +
      "'" +
      "\n  OutputPort: '" +
      this.GetOutputPortName() +
      "'" +
      "\n  Probability: '" +
      this.Probability +
      "'" +
      '\n}'
    );
  }
}
