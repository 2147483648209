import log from 'loglevel';
import ExerciseNode from './ExerciseNode';
import NodePort from '../NodePort';
import ParticipantsModule from '../../Participants/ParticipantsModule';

export default class StatusChange extends ExerciseNode {
  // Ports
  Input = new NodePort('Input', 'input', this);

  // Parameters
  // Create parameters here
  /*"Me":true,"BotName":"","ApplyOn_Joining":false,"Joining":false,"ApplyOn_MuteCamera":false,"MuteCamera":false,"ApplyOn_MuteMicrophone":true,"MuteMicrophone":true,"ApplyOn_TalkingOverlay":false,"TalkingOverlay":false,"ApplyOn_RaisingHandOverlay":false,"RaisingHandOverlay":true,"ApplyOn_Heart":false,"Heart":false,"ApplyOn_Laught":false,"Laught":false,"ApplyOn_Applause":false,"Applause":false,"ApplyOn_Angry":false,"Angry":false,"ApplyOn_Inch":false,"Inch":false*/
  Me = false;
  BotName = '';
  ApplyOn_Joining = false;
  Joining = false;
  ApplyOn_MuteCamera = false;
  MuteCamera = false;
  ApplyOn_MuteMicrophone = false;
  MuteMicrophone = false;
  ApplyOn_TalkingOverlay = false;
  TalkingOverlay = false;
  ApplyOn_RaisingHandOverlay = false;
  RaisingHandOverlay = false;
  ApplyOn_Heart = false;
  Heart = false;
  ApplyOn_Laught = false;
  Laught = false;
  ApplyOn_Applause = false;
  Applause = false;
  ApplyOn_Angry = false;
  Angry = false;
  ApplyOn_Inch = false;
  Inch = false;

  // Internal values

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);

    //log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName + ", id = " + this.ID + ", properties = " + JSON.stringify(iProperties));

    // Set parameters
    this.Me = iProperties.Me;
    this.BotName = iProperties.BotName;
    this.ApplyOn_Joining = iProperties.ApplyOn_Joining;
    this.Joining = iProperties.Joining;
    this.ApplyOn_MuteCamera = iProperties.ApplyOn_MuteCamera;
    this.MuteCamera = iProperties.MuteCamera;
    this.ApplyOn_MuteMicrophone = iProperties.ApplyOn_MuteMicrophone;
    this.MuteMicrophone = iProperties.MuteMicrophone;
    this.ApplyOn_TalkingOverlay = iProperties.ApplyOn_TalkingOverlay;
    this.TalkingOverlay = iProperties.TalkingOverlay;
    this.ApplyOn_RaisingHandOverlay = iProperties.ApplyOn_RaisingHandOverlay;
    this.RaisingHandOverlay = iProperties.RaisingHandOverlay;
    this.ApplyOn_Heart = iProperties.ApplyOn_Heart;
    this.Heart = iProperties.Heart;
    this.ApplyOn_Laught = iProperties.ApplyOn_Laught;
    this.Laught = iProperties.Laught;
    this.ApplyOn_Applause = iProperties.ApplyOn_Applause;
    this.Applause = iProperties.Applause;
    this.ApplyOn_Angry = iProperties.ApplyOn_Angry;
    this.Angry = iProperties.Angry;
    this.ApplyOn_Inch = iProperties.ApplyOn_Inch;
    this.Inch = iProperties.Inch;
  }

  async OnActivated(iActivationLink, iIsRewindMode = false) {
    await super.OnActivated(iActivationLink, iIsRewindMode);

    if (iIsRewindMode) {
      return;
    }

    if (this.Me) {
      let human = ParticipantsModule.Instance.GetHuman();

      if (this.ApplyOn_TalkingOverlay) {
        log.debug(this.GetIdentity() + ' is setting talking overlay to ' + this.TalkingOverlay);
        human.setSpeakingState(this.TalkingOverlay ? 'speaking' : 'no');
      }
      if (this.ApplyOn_RaisingHandOverlay) {
        log.debug(
          this.GetIdentity() + ' is setting raising hand overlay to ' + this.RaisingHandOverlay
        );
        human.setSpeakingState(this.RaisingHandOverlay ? 'raisingHand' : 'no');
      }
      if (this.ApplyOn_Joining) {
        log.debug(this.GetIdentity() + ' is setting joining to ' + this.Joining);
        human.setConnectionState(this.Joining ? 'connecting' : 'connected');
      }
      if (this.ApplyOn_MuteCamera) {
        log.debug(this.GetIdentity() + ' is setting mute camera to ' + this.MuteCamera);
        human.setCamState(this.MuteCamera ? 'off' : 'on');
      }
      if (this.ApplyOn_MuteMicrophone) {
        log.debug(this.GetIdentity() + ' is setting mute microphone to ' + this.MuteMicrophone);
        human.setMic(this.MuteMicrophone ? 'off' : 'on');
      }
    } else {
      let bot = ParticipantsModule.Instance.GetBot(this.BotName);

      if (this.ApplyOn_TalkingOverlay) {
        log.debug(
          this.GetIdentity() +
            " is setting bot '" +
            this.BotName +
            "' talking overlay to " +
            this.TalkingOverlay
        );
        bot.setSpeakingState(this.TalkingOverlay ? 'speaking' : 'no');
      }
      if (this.ApplyOn_RaisingHandOverlay) {
        log.debug(
          this.GetIdentity() +
            " is setting bot '" +
            this.BotName +
            "' raising hand overlay to " +
            this.RaisingHandOverlay
        );
        bot.setSpeakingState(this.RaisingHandOverlay ? 'raisingHand' : 'no');
      }
      if (this.ApplyOn_Joining) {
        log.debug(
          this.GetIdentity() + " is setting bot '" + this.BotName + "' joining to " + this.Joining
        );
        bot.setConnectionState(this.Joining ? 'connecting' : 'connected');
      }
      if (this.ApplyOn_MuteCamera) {
        log.debug(
          this.GetIdentity() +
            " is setting bot '" +
            this.BotName +
            "' mute camera to " +
            this.MuteCamera
        );
        bot.setCamState(this.MuteCamera ? 'off' : 'on');
      }
      if (this.ApplyOn_MuteMicrophone) {
        log.debug(
          this.GetIdentity() +
            " is setting bot '" +
            this.BotName +
            "' mute microphone to " +
            this.MuteMicrophone
        );
        bot.setMic(this.MuteMicrophone ? 'off' : 'on');
      }
      /*if(this.ApplyOn_Heart)
            {
                bot.setEmoji(this.Heart ? "heart" : "no");
            }
            if(this.ApplyOn_Laught)
            {
                bot.setEmoji(this.Laught ? "laugh" : "no");
            }
            if(this.ApplyOn_Applause)
            {
                bot.setEmoji(this.Applause ? "applause" : "no");
            }
            if(this.ApplyOn_Angry)
            {
                bot.setEmoji(this.Angry ? "angry" : "no");
            }
            if(this.ApplyOn_Inch)
            {
                bot.setEmoji(this.Inch ? "inch" : "no");
            }*/
    }

    this.SetActive(false);
  }

  PrintParameters() {}
}
