import React, { useContext, useEffect, useState } from 'react';
import log from 'loglevel';
import Animation from '@/components/Utilities/Animation';
import { Translation } from '@/components/Utilities/Translation';

const LegacyUserActionsToast = () => {
  const [actions, setActions] = useState([]);

  // Function to generate a unique ID for each user action toast
  const generateUniqueId = () => {
    return Math.random().toString(36).substr(2, 9);
  };

  // useEffect hook to handle events when the component mounts
  useEffect(() => {
    window.sdk.event().on('popUserActionsToasts', (actions) => {
      // Process actions to ensure uniqueness and limit to first 3
      actions = Array.from(
        actions.reduce((map, action) => map.set(action.Type, action), new Map()).values()
      ).slice(0, 3);

      actions.forEach((action, i) => {
        const index = i + 1;

        setTimeout(() => {
          const idx = generateUniqueId();
          const newAction = { ...action, idx };
          setActions((prevActions) => [...prevActions, newAction]);

          let element;
          setTimeout(() => {
            element = document.querySelector('.action-' + idx); // Find the action element in the DOM

            if (!element) {
              log.debug('Element not found');
              return;
            }

            element.classList.add('achievement-action-show'); // Add class to show the action

            setTimeout(() => {
              window.sdk.event().emit('playLottie', 'user-action-toast-' + idx); // Emit event to play animation
            }, 400);
          }, 500);

          setTimeout(
            () => {
              element = document.querySelector('.action-' + idx); // Find the action element again

              if (element) {
                element.classList.add('fade-out'); // Add fade-out class
                element.classList.remove('achievement-action-show'); // Remove show class

                setTimeout(() => {
                  let listNotif = document.querySelectorAll('.achievement-action-show');
                  if (listNotif.length === 0) {
                    const element = document.querySelector('.achievement-push');
                    if (element) {
                      element.innerHTML = ''; // Clear inner HTML if no notifications are left
                    }
                  }
                }, 5000);
              }
            },
            5000 + 500 * index // Timeout duration for fade-out
          );
        }, 1000);
      });
    });
  }, []);

  // Render the component
  return (
    <div className="achievement-push">
      {actions.map((action, key) => (
        action.Strategic ? (
          <div
            className={'achievement-push-item action-' + action.idx}
            id={'userAction-' + action.DisplayedName}
            key={key}
          >
            <Animation
              type={action.Type}
              name={'user-action-toast-' + action.idx}
              autoplay={false}
              loop={false}
            />
            <div className="achievement-push-item-content">
              <div className="achievement-push-item-type">
                <Translation keyName="strategicActionsPop.strategy">Stratégie</Translation>
              </div>
              {action.DisplayedName}
            </div>
          </div>
        ) : null
      ))}
    </div>
  );
};

export default LegacyUserActionsToast;
